<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
        <table class="detailTable_search" style="width: 100%">
          <tr>
          <td class="titleTd width40px">检测日期</td>
          <td class="valueTd">
            <div class="block" >
              <el-date-picker
                v-model="dataForm.time" 
                type="datetimerange"
                align="right"
                unlink-panels
          
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeHandler"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </td>
          <td class="titleTd width40px">文件名称</td>
          <td class="valueTd">
            <el-input style="width: 100%" v-model="dataForm.fileName" placeholder="请输入文件名称进行检索" clearable />
          </td>
          <td class="titleTd width40px">文件类型</td>
          <td class="valueTd">
            <sysDictSelect  style="width:100%"  v-model="dataForm.fileType"  dictType="dataFileType"  placeholder="文件类型" />
          </td>
          <td class="titleTd width40px">文件处理状态</td>
          <td class="valueTd">
            <sysDictSelect  style="width:100%"  v-model="dataForm.processState"  dictType="process_state"  placeholder="文件处理状态" />
          </td>
          </tr>
        </table>
      </el-form>
      <div class="wxts_msg_search" >
        <div class="f_t">
          <el-button-group  >
            <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">查询</el-button>
            <el-button size="mini" type="primary" @click="dealHandle()" :disabled="dataListSelections.length <= 0">批量处理</el-button>
          </el-button-group>
        </div>
      </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        :selectable='disCell'
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        label="序号"
        width="50">
      </el-table-column>
      <el-table-column
        prop="fileName"
        header-align="center"
        align="center"
        label="文件名称">
      </el-table-column>
      <el-table-column
        prop="fileType"
        header-align="center"
        align="center"
        label="文件类型">
        <template slot-scope="scope">
          <sysDict type="dataFileType" :value="scope.row.fileType" />
        </template>
      </el-table-column>
      <el-table-column
        prop="processState"
        header-align="center"
        align="center"
        label="文件状态">
        <template slot-scope="scope">
          <sysDict type="process_state" :value="scope.row.processState" />
        </template>
      </el-table-column>
      <el-table-column
        prop="totalNum"
        header-align="center"
        align="center"
        label="记录数">
      </el-table-column>
      <el-table-column
        prop="detectTime"
        header-align="center"
        align="center"
        label="文件处理时间">
      </el-table-column>
      <el-table-column
        prop="duration"
        header-align="center"
        align="center"
        label="处理时长">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="dealHandle(scope.row)" v-if="scope.row.processState==1">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
  </div>
</template>

<script>
  import {getList,dealData} from "@/api/filemonitoring/dataFile";
  export default {
    data () {
      return {
        dataForm: {
          startTime:'',
          endTime:'',
          fileName:'',
          processState:'',
          fileType:'',
          time:[]
        },
        deal:'',
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    components: {
    },
    activated () {
      this.dataForm.key =  this.$route.query.key;
      
      let pageIndex = this.$route.query.pageIndex; 
      if(pageIndex) {
        this.pageIndex = parseInt(pageIndex); 
      }

      let pageSize = this.$route.query.pageSize; 
      if(pageSize) {
        this.pageSize = parseInt(pageSize); 
      }
     
      this.getDataList();
    },
    computed: {
    regionId: {
            get() {
                const newregionId = this.$store.state.user.regionId;
                if (newregionId !== undefined) {
                    return Number(newregionId);
                } else {
                    return ''
                }
            }
        },
        gridId: {
            get() {
                const newgridId = this.$store.state.user.gridId;
                if (newgridId !== undefined) {
                    return Number(newgridId);
                } else {
                    return ''
                }
            }
        },
    },
    methods: {
      disCell (row, rowIndex) {
          return row.processState === 1
        },
    //   changeHandler(val){
    //   if(val != null){
    //     this.dataForm.detectTime = this.dealdate(val);
    //   }else{
    //     this.dataForm.detectTime = '';
    //   }
    // },
    changeHandler(val){
      this.dataForm.time = val;
      if(this.dataForm.time != null){
        this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
        this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
      }else{
        this.dataForm.startTime = '';
        this.dataForm.endTime = '';
      }
    },
       refresh(){
        // this.$router.push({name:'item_sitemonethingaddress',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
      },
      search(){
         this.pageIndex = 1 ;
         this.getDataList();
         this.refresh();
      }, 
      //获取数据列表
      getDataList() {
      const _this = this;
      getList(
        {
          page: this.pageIndex,
          limit: this.pageSize,
          startTime:this.dataForm.startTime,
          endTime:this.dataForm.endTime,
          fileName:this.dataForm.fileName,
          processState:this.dataForm.processState,
          fileType:this.dataForm.fileType
        }
      ).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list
            this.totalPage = data.data.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
        this.refresh()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
        this.refresh()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      //处理   
      dealHandle(row){
        const ids = row ? [row.id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定要进行[${row ? '处理' : '批量处理'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.dataListLoading = true
          dealData(ids).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000
              })
              this.getDataList()
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            console.error(err)
          }).finally(()=>{
          	this.dataListLoading = false
          })
        }).catch(_ => {})
      },
      // 日期转换
    dealdate(date) {
      const dt = new Date(date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    }
    }
  }
</script>
