import http from '@/utils/httpRequest'


// 查询文件处理列表
export function getList(params) {
    return http({
        url: http.adornUrl('/dataflow/olcdatafile/list'),
        method: 'get',
        params: params
    })
}

//手动处理
export function dealData(ids = []) {
    return http({
        url: http.adornUrl('/dataflow/olcdatafile/deal'),
        method: 'post',
        data:  http.adornData(ids, false)
    })
}
